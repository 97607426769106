import * as React from "react";
import { AddToCartTireButtonCard } from "../cart/addToCartTireButton/addToCartTireButton";
import * as StyledComponets from "./tirePageDisplay.module.scss";
import snow from "../../icons/snow.svg";

// markup
export const TirePageDisplay = ({
  image,
  sku,
  brand,
  name,
  price,
  width,
  aspect,
  diam,
  season,
  montreal,
  toronto,
  vancouver,
}) => {
  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  function isWinterProof() {
    if (season !== "summer") {
      console.log(season);
      return <div className={StyledComponets.snow}></div>;
    } else {
      return (
        <img className={StyledComponets.snow} src={snow} alt="winter proof" />
      );
    }
  }
  price = Number.parseFloat(price).toFixed(2);
  return (
    <article className={StyledComponets.article}>
      <img
        className={StyledComponets.magimage}
        loading="lazy"
        src={image}
        alt={brand + " " + width + "/" + aspect + "/" + diam}
      />
      <div className={StyledComponets.card} key={sku}>
        <div className={StyledComponets.snowContainer}>{isWinterProof()}</div>
        <h3>{brand}</h3>
        <h4>{name}</h4>
        <h5>{width + "/" + aspect + "R" + diam}</h5>
        <h6>{sku}</h6>
        <h2>{formatter.format(price)}</h2>
        <AddToCartTireButtonCard
          ximage={image}
          xsku={sku}
          xbrand={brand}
          xmodel={name}
          xprice={price}
          xwidth={width}
          xaspect={aspect}
          xdiam={diam}
          xseason={season}
        />
      </div>
      <div className={StyledComponets.inventory}>
        <h2>Inventaire :</h2>
        <p>MONTREAL : {montreal}</p>
        <p>TORONTO : {toronto}</p>
        <p>VANCOUVER : {vancouver}</p>
      </div>
    </article>
  );
};
