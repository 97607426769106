import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/layout/layout";
import { TirePageDisplay } from "../components/tirePageDispaly/tirePageDisplay";
import Container from "../components/container/container";

// markup
const TirePage = () => {
  const [IMAGE, setIMAGE] = useState();
  const [SKU, setSKU] = useState();
  const [BRAND, setBRAND] = useState();
  const [NAME, setNAME] = useState();
  const [PRICE, setPRICE] = useState();
  const [WIDTH, setWIDTH] = useState();
  const [ASPECT, setASPECT] = useState();
  const [DIAM, setDIAM] = useState();
  const [SEASON, setSEASON] = useState();
  const [MONTREAL, setMONTREAL] = useState();
  const [TORONTO, setTORONTO] = useState();
  const [VANCOUVER, setVANCOUVER] = useState();

  function getItemInfos(givenSKU) {
    let fetchURL = "https://www.wheels-api.com/tires?sku_eq=" + givenSKU;
    fetch(fetchURL)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setIMAGE(result[0].image);
          setSKU(result[0].sku);
          setBRAND(result[0].brand);
          setNAME(result[0].model);
          setPRICE(result[0].price);
          setWIDTH(result[0].sectionWidth);
          setASPECT(result[0].aspectRatio);
          setDIAM(result[0].diameter);
          setSEASON(result[0].season);
          setMONTREAL(result[0].montreal);
          setTORONTO(result[0].toronto);
          setVANCOUVER(result[0].vancouver);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    //get the value of the SKU on the URL
    function getURLparameters() {
      let url = new URL(document.location.href);
      url.searchParams.sort();
      let keys = url.searchParams.keys();
      for (let key of keys) {
        let val = url.searchParams.get(key);
        val = val.replace(" ", "%2B");
        if (key === "sku") {
          getItemInfos(val);
        }
      }
    }
    getURLparameters();
  }, []);

  return (
    <PageLayout>
      <Container>
        <TirePageDisplay
          image={IMAGE}
          sku={SKU}
          brand={BRAND}
          name={NAME}
          price={PRICE}
          width={WIDTH}
          aspect={ASPECT}
          diam={DIAM}
          season={SEASON}
          montreal={MONTREAL}
          toronto={TORONTO}
          vancouver={VANCOUVER}
        />
      </Container>
    </PageLayout>
  );
};
export default TirePage;
